import {
  Container,
  Grid,
} from '@mui/material'

import FeatureCard from './FeatureCard'

import { FEATURES } from './content'

const sectionStyles = {
  pt: 4,
}

function Features() {
  const {
    battle,
    builds,
    companion,
    crafting,
    exploration,
    more,
  } = FEATURES

  return (
    <Container
      component="section"
      id="features"
      sx={sectionStyles}
    >
      <Grid container
        rowSpacing={5}
        columnSpacing={4}
        justifyContent="center"
      >
        <FeatureCard
          icon={exploration.svg}
          title={exploration.title}
          content={exploration.content}
        />
        <FeatureCard
          icon={battle.svg}
          title={battle.title}
          content={battle.content}
        />
        <FeatureCard
          icon={crafting.svg}
          title={crafting.title}
          content={crafting.content}
        />
        <FeatureCard
          icon={companion.svg}
          title={companion.title}
          content={companion.content}
        />
        <FeatureCard
          icon={builds.svg}
          title={builds.title}
          content={builds.content}
        />

        <FeatureCard
          variant="highlight"
          title={more.title}
          content={more.content}
          cta={{
            label: "Join the community",
            href: "#join",
          }}
        />
      </Grid>
    </Container>
  )
}

export default Features
