export const BRAND = 'mutopi'

export const WAITLIST_URL = 'https://forms.gle/zHmpxtJcjXCyZjdx8'
export const DISCORD_URL = 'https://discord.gg/2FnkQwGVAh'
export const FACEBOOK_URL = 'https://www.facebook.com/Mutopi-105094675620535'
export const GITHUB_URL = 'https://github.com/mutopi'
export const INSTAGRAM_URL = 'https://www.instagram.com/mutopilabs/'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/mutopi/'
export const MEDIUM_URL = 'https://medium.com/@mutopi'
export const REDDIT_URL = 'https://www.reddit.com/r/mutopi/'
export const TELEGRAM_URL = 'https://t.me/+QBhDPHBEp-xmNGYx'
export const TIKTOK_URL = 'https://www.tiktok.com/@mutopilabs/'
export const TWITCH_URL = 'https://www.twitch.tv/mutopilabs/'
export const TWITTER_URL = 'https://twitter.com/mutopilabs/'
export const YOUTUBE_URL = 'https://www.youtube.com/@mutopi/'

export const NAVIGATION = [
  { anchor: '#main', text: 'Home', options: { sx: { color: 'white' } } },
  { anchor: '#about', text: 'Learn more', options: { sx: { color: 'white' } } },
  { anchor: '#storyline', text: 'Storyline', options: { sx: { color: 'white' } } },
  { anchor: WAITLIST_URL, text: 'Join waitlist', options: { variant: 'contained' } },
]
