import Layout from 'lib/components/Layout'

import AboutSection from 'pages/Home/sections/About'
import FeaturesSection from 'pages/Home/sections/Features'
import HeroSection from 'pages/Home/sections/Hero'
import StreamSection from 'pages/Home/sections/Stream'
import JoinSection from 'pages/Home/sections/Join'
import StorySection from 'pages/Home/sections/Story'

const Home = () => (
  <Layout>
    <HeroSection />

    <AboutSection />

    <FeaturesSection />

    <StreamSection />

    <JoinSection />

    <StorySection />
  </Layout>
)

export default Home
