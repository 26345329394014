import {
  Companion,
  Inventory,
  MapMarker,
  Rift,
  Skills,
} from 'lib/components/CustomIcons'

export const FEATURES  = {
  exploration: {
    svg: (<MapMarker />),
    title: "Explore the world",
    content: [
      "Discover a new exiting reality as you explore your surroundings. Valuable materials and items and creatures of all shapes and sizes are lurking on every corner of your city and beyond.",
    ],
  },
  battle: {
    svg: (<Rift />),
    title: "Fight the unknown",
    content: [
      "Defeate creatures to learn about their characteristics and skills. Take advantage of that knowledge and the environment around you to weaken them, make your abilities stronger.",
    ],
  },
  crafting: {
    svg: (<Inventory />),
    title: "Tinker and craft",
    content: [
      "Gather materials and objects and learn about their composition to unlock crafting recipes and make more powerful items and boosts that will help you and your friends on your journeys.",
    ],
  },
  companion: {
    svg: (<Companion />),
    title: "Grow stronger",
    content: [
      "A mysterious entity will be guide you through your journey. As you strengthen your bond with your companion, you will progress on the storyline while unlocking memories of their past life, personality traits and their full potential.",
    ],
  },
  builds: {
    svg: (<Skills />),
    title: "Write your own story",
    content: [
      "Become an explorer, a master crafter, a fierce fighter, a cunning merchant or a combination of all of them.",
      "Experience the storyline and customize your playstyle around what feels important to you.",
    ],
  },
  more: {
    title: "It's only the beginning",
    content: [
      "These are just some of the many many things we have planned for mutopi.",
      "We are just getting started and we'd love to hear your ideas and suggestions.",
    ],
  },
}
