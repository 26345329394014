import { Container } from '@mui/material'

import AnimatedTitle from 'lib/components/AnimatedTitle'
import Highlight from 'lib/components/Highlight'

import Carousel from './Carousel'

import {STORY} from './content'

function Story() {
  return (
    <Container
      component="section"
      id="storyline"
      sx={{ py: 10 }}
    >
      <AnimatedTitle>
      <Highlight>{STORY.TITLE}</Highlight>
      </AnimatedTitle>

      <Carousel content={STORY.CHAPTERS}/>
    </Container>
  )
}

export default Story
