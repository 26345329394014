import {
  Box,
  Button,
  Container,
  Fade,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import { WAITLIST_URL } from 'lib/constants'

import { HERO } from './content'

const sectionStyles = {
  backgroundImage: 'url(/heros/bearer.jpg)',
  backgroundPosition: {
    xs: '60% center',
    sm: 'center center',
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100vh',
  maskImage: 'linear-gradient(to top, transparent 1px, black 20%)',
  userSelect: 'none',
}


const StaticHero = () => (
  <Box
    component="section"
    id="main"
    sx={sectionStyles}
  >
    <Container sx={{ height: '100%' }}>
      <Fade in timeout={3000}>
        <Grid container
          height="100%"
          direction="column"
          justifyContent="flex-end"
          paddingBottom={{
            xs: 15,
            sm: 20,
          }}
        >
          <Grid item>
            <Typography
              gutterBottom
              variant="h1"
              sx={{ textShadow: '1px 1px 4px black;' }}
            >
              {HERO.TITLE_A}
              <br />
              {HERO.TITLE_B}
            </Typography>
          </Grid>

          <Grid item>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
            >
              <Button
                disableElevation
                href={WAITLIST_URL}
                variant="contained"
              >
                {HERO.PRIMARY_CTA}
              </Button>
              <Button
                color="darkBlue"
                disableElevation
                href="#about"
                variant="contained"
              >
                {HERO.SECONDARY_CTA}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Fade>
    </Container>
  </Box>
)

export default StaticHero
