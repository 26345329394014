import { useState, useEffect } from 'react'
import { Container } from '@mui/material'

import Layout from 'lib/components/Layout'
import Map from './Map'
import Intro from './Intro'


function Explore() {
  const [position, setPosition] = useState({ lat: 40.712775, lng: -74.005972 })
  const [showMap, setShowMap] = useState(false)

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(({ state }) => {
          if (state === 'granted') {
            handleShowMap(true)
          }
        })
      }
  }, [])

  const handleShowMap = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setPosition({
          lat: coords.latitude,
          lng: coords.longitude,
        })

        setShowMap(true)
      })
    }
  }

  const content = showMap
    ? <Map position={ position } />
    : <Intro handleClick={handleShowMap} />

  return (
    <Layout>
      <Container
        sx={{ pt: 18 }}
        style={{ height: '100%' }}
      >
        {content}
      </Container>
    </Layout>
  )
}

export default Explore
