import { Typography } from '@mui/material'

const styles = () => ({
  color: 'primary.main',
  fontWeight: 'bold',
  textShadow: '0px 4px 5px hsl(0, 0%, 0%, 25%);',
})

const Highlight = props => (
  <Typography component="span" variant="inherit" sx={styles} {...props} />
)

export default Highlight
