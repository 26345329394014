import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material'

const CARD_BACKGROUND = {
  highlight: 'linear-gradient(to bottom, rgb(62, 178, 158, 40%), transparent)',
  shadow: 'linear-gradient(to bottom, rgb(62, 178, 158, 10%), transparent)',
}

const FeatureCard = ({
  variant = 'shadow',
  cta,
  content,
  icon,
  title,
}) => {
  const body = content.map((paragraph, index) => (
    <Typography
      key={index}
      sx={{ mt: 2 }}
      variant="body2"
    >
      {paragraph}
    </Typography>
  ))
  const ctaButton = cta && (
    <Button
      href={cta.href}
      variant="contained"
      sx={{ mt: 4 }}
    >
      {cta.label}
    </Button>
  )
  const background = CARD_BACKGROUND[variant]

  return (
    <Grid item
      lg={4}
      md={6}
      xs={12}
    >
      <Box
        sx={{
          background,
          borderRadius: '20px',
          py: 5,
          px: 3,
          textAlign: 'center',
        }}
      >
        {icon}
        <Typography variant="h4" sx={{ mt: 3 }}>
          {title}
        </Typography>
        {body}
        {ctaButton}
      </Box>
    </Grid>
  )
}

export default FeatureCard
