//
// Colors
//

// -- CONSTANTS --

const darkBlue = {
  main: 'hsl(225, 50%, 5%)',
  light: 'hsl(208, 63%, 11%)',
}

const primary = {
  main: 'hsl(170, 48%, 47%)',
  light: 'hsl(170, 48%, 57%)',
}

const secondary = {
  main: 'hsl(256, 49%, 47%)',
}

const background = {
  default: darkBlue.main,
}


// -- THEME --

export const palette = {
  mode: 'dark',
  primary,
  secondary,
  background,
  darkBlue,
}
