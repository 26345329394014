const API_URL = '-qotnr5ivuq-uc.a.run.app/'

const defaultOptions = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  headers: { 'Content-Type': 'application/json' },
}

export const fetchApi = async (action, params, opts = {}) => {
  const options = Object.assign({}, defaultOptions, opts)

  const response = await fetch(`https://${action}${API_URL}${params}`, options)

  return response.json()
}

export const get = (action, params) => {
  return fetchApi(action, params)
}
