import { useState } from 'react'

import ChaptersCarousel from './ChaptersCarousel'

function StoryCarousel({content}) {
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = content.length

  return (
    <ChaptersCarousel
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      maxSteps={maxSteps}

      content={content}
    />
  )
}

export default StoryCarousel
