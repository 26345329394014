import {
  Fade,
  Typography,
} from '@mui/material'

function AnimatedTitle(props) {
  const {
    children,
    variant = 'h2',
    sx,
  } = props

  return (
    <Fade in timeout={3000}>
      <Typography gutterBottom variant={variant} sx={sx}>
        {children}
      </Typography>
    </Fade>
  )
}

export default AnimatedTitle
