import {
  Container,
  Link,
  Typography,
} from '@mui/material'

import { LogoNoISO } from 'lib/components/CustomIcons'

function Footer() {
  const today = new Date()
  const year = today.getFullYear()

  return (
    <Container
      component="footer"
      disableGutters
      maxWidth={false}
      sx={{ py: 5, marginTop: 'auto' }}
    >
        <Typography gutterBottom textAlign="center">
          &copy; {year} &ensp;
          <Link href="#main" underline="none">
            <LogoNoISO />
          </Link>
        </Typography>
        <Typography gutterBottom variant="body2" textAlign="center" >
          All rights reserved.
        </Typography>
    </Container>
  )
}

export default Footer
