import { Grid, Typography, Button, Stack } from '@mui/material'

const Intro = ({handleClick})  => (
  <Grid container
    direction="column"
    height="100%"
    justifyContent="center"
  >
    <Grid item>
      <Typography gutterBottom variant="h2">
        Thank you for being here!
      </Typography>
      <Typography gutterBottom variant="body1">
        The following section is meant to show players an interactive map of the real world, where they can explore and discover the real-life locations that will be available as part of mutopi.
      </Typography>
      <Typography gutterBottom variant="body1">
        In order to do that, you'll be requested to share your location. We do this to make sure that the map is populated by the time the game gets launched, and everyone has interesting content to explore around them.
      </Typography>
      <Typography gutterBottom variant="body1">
        Rest assured that this information is only temporarily stored, just until the population process is done around that given location.
      </Typography>
      <Typography gutterBottom variant="body1">
        So, if you agree with that, go ahead, explore, discover, and let's create something extraordinary together!
      </Typography>
      <Typography gutterBottom variant="body1">
        Every click, every interaction, and every moment you spend here contributes to bringing mutopi into the world. Your support is what makes this project possible. Thank you!
      </Typography>
    </Grid>

    <Grid item sx={{ mt: 5 }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2 }}
      >
        <Button
          disableElevation
          variant="contained"
          onClick={handleClick}
        >
          Let me explore!
        </Button>
        <Button
          disableElevation
          href="https://mutopi.com/"
          variant="outlined"
        >
          Take me back
        </Button>
      </Stack>
    </Grid>
  </Grid>
)

export default Intro
