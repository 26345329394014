import { Container, Typography } from '@mui/material'

import Highlight from 'lib/components/Highlight'
import AnimatedTitle from 'lib/components/AnimatedTitle'

import { ABOUT } from './content'

const sectionStyles = {
  pt: 15,
  pb: 4,
}

function AboutSection() {
  return (
    <Container
      component="section"
      id="about"
      sx={sectionStyles}
    >
      <AnimatedTitle>
        {ABOUT.TITLE_A} <Highlight>{ABOUT.TITLE_B}</Highlight>
      </AnimatedTitle>

      {ABOUT.CONTENT.map((paragraph, index) => (
        <Typography
          gutterBottom
          key={index}
          variant="body1"
        >
          {paragraph}
        </Typography>
      ))}
    </Container>
 )
}

export default AboutSection
