const SvgGlow = ({ id, offsetY, ...props }) => (
  <defs>
    <filter id={id} x="0" y="0" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" {...props}>
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
      <feOffset dy={offsetY} />
      <feGaussianBlur stdDeviation="15"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.397674 0 0 0 0 0.91066 0 0 0 0 0.818586 0 0 0 0.3 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
    </filter>
  </defs>
)

const FeatureIcon = ({
  name,
  children,
  glowStyles,
  height = '130',
  width = '130',
  viewBox = '0 0 130 130',
  ...props
}) => {
  const glowFilterId = `${name}-glow`

  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height={width}
      width={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter={`url(#${glowFilterId})`}>
        {children}
      </g>
      <SvgGlow
        {...glowStyles}
        id={glowFilterId}
        height={height}
        width={width}
      />
    </svg>
  )
}

export default FeatureIcon
