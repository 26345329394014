//
// Typography
//

// -- CONSTANTS --

const {
  fontFamily,
  fontSize,
  htmlFontSize,
  light,
  regular,
  medium,
  semiBold,
  bold,
  textTransform,
} = {
  fontFamily: '"Source Sans 3", sans-serif',
  fontSize: 16,
  // htmlFontSize: 16,
  // light: 300,
  // regular: 400,
  // medium: 500,
  // semiBold: 600,
  bold: 700,
}

// -- VARIANTS --

const fontVariants = {
  h1: {
    fontSize: 60,
  },
  h2: {
    fontSize: 48,
  },
  h3: {
    fontSize: 36,
  },
  h4: {
    fontSize: 20,
    fontWeight: bold,
  },
  body1: {
    fontSize: 20,
  },
  // body2: { // using default 16px
  //   fontSize: 16,
  // },
}


// -- THEME --

export const typography = {
  fontFamily,
  fontSize,
  htmlFontSize,
  fontWeightLight: light,
  fontWeightRegular: regular,
  fontWeightMedium: medium,
  fontWeightSemiBold: semiBold,
  fontWeightBold: bold,
  textTransform,
  ...fontVariants,
}
