import {
  Box,
} from '@mui/material'

import Layout from 'lib/components/Layout'

const sectionStyles = {
  px: {
    xs: 5,
    sm: 15,
    md: 35,
    lg: 55,
    xl: 65,
  },
  py: 20,
  textAlign: 'center'
}

function FeedbackPage() {
  return (
    <Layout>
      <Box
        id="main"
        component="section"
        disableGutters
        sx={sectionStyles}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/0la5DBtOVNI"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        >
        </iframe>
      </Box>
    </Layout>
  )
}

export default FeedbackPage
