import { useRef, useEffect } from 'react'
import { Container, Typography } from '@mui/material'

import Highlight from 'lib/components/Highlight'
import AnimatedTitle from 'lib/components/AnimatedTitle'

function Stream() {
  const twitchPlayer = useRef(null)

  useEffect(() => {
    if (document.querySelector('#twitch-embed iframe')) return

    twitchPlayer.current = new window.Twitch.Player('twitch-embed', {
      autoplay: false,
      channel: 'mutopilabs',
      muted: true,
      height: '365px',
      width: '100%',
    })
  }, [])

  return (
    <Container
      component="section"
      id="stream"
      sx={{ py: 10 }}
    >
      <AnimatedTitle>
        Live Twitch <Highlight>DevStream</Highlight>
      </AnimatedTitle>
      <Typography
        gutterBottom
        variant="body1"
        sx={{ mb: 2 }}
      >
        We are streaming the whole development process live (almost) every day on Twitch. If you, or someone you know, are curious about making games, or if you'd like to contribute with your thoughts and ideas. Come and say hi!
      </Typography>
      <div ref={twitchPlayer} id="twitch-embed"></div>
    </Container>
  )
}

export default Stream
