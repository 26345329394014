import { IconButton } from '@mui/material'

import {
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from '@mui/icons-material/'

import {
  DiscordIcon,
  TwitchIcon,
} from 'lib/components/CustomIcons'

import {
  DISCORD_URL,
  TWITCH_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from 'lib/constants'

const SocialLink = ({
  url,
  ...props
}) => (
  <IconButton color="primary" href={url} target="_blank" {...props} />
)

function SocialLinks({ iconSize }) {
  return (
    <>
      <SocialLink url={TWITCH_URL} >
        <TwitchIcon fontSize={iconSize} />
      </SocialLink>
      <SocialLink url={YOUTUBE_URL} >
        <YouTubeIcon fontSize={iconSize} />
      </SocialLink>
      <SocialLink url={TWITTER_URL} >
        <TwitterIcon fontSize={iconSize} />
      </SocialLink>
      <SocialLink url={DISCORD_URL} >
        <DiscordIcon fontSize={iconSize} />
      </SocialLink>
    </>
  )
}

export default SocialLinks
