export const STORY = {
  TITLE: 'Storyline',
  CHAPTERS: [
    {
      alt: 'Creatures from a different plane of existence appearing out of nowhere through portals.',
      body: 'Reality began to warp and crack. Portals, or "Breaches" as some called them, opened up across the world, connecting different planes of existence together. Horrifying creatures from these places poured out into the world and started to consume everything and everyone in their way.',
      label: 'chapter-1',
      image: '/story/rifts-min.png',
      objectPosition: '50% 60%',
    },
    {
      alt: 'A person emanating a surge of energy while laying down in a machine operated by two technicians.',
      body: 'We were not strong enough to fight them, but we were not ready to give up either. We wanted to find a way to fight back, a chance for survival. Even if that meant risking the lives of the few of us that were left. I was among those who attempted to reach your plane.',
      image: '/story/transfer-min.png',
      label: 'chapter-2',
      objectPosition: '50% 40%',
    },
    {
      alt: 'A confident person, wrapped and empowered by a being completely made of energy.',
      body: 'I lost my physical form during the shift. You found me, or at least a speck of what I once was. Now, my essence is with you and many others. Together, we will find a way to stop this.',
      label: 'chapter-3',
      image: '/story/bearer-min.png',
      objectPosition: '50% 60%',
    },
  ]
}
