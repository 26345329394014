import { BRAND } from 'lib/constants'

export const ABOUT = {
    TITLE_A: "Rethinking",
    TITLE_B: "reality",
    CONTENT: [
        `${BRAND} is an Augmented Reality (AR) Role-Playing Game (RPG) taking place in the real world and designed to blur the line between reality and fiction.`,
        "We are creating a new layer of reality on top of the world we live in to give people the opportunity to build a dynamic and vibrant community and economic system, to be part of a new world and a new way of living.",
    ],
}
