import { collection, getDocs } from "firebase/firestore"

import { db } from 'utils/firebase'

import { get } from 'utils/request'

export const getSpots = async () => {
  const querySnapshot = await getDocs(collection(db, "spots"))

  return querySnapshot.docs.map((doc) => doc.data())
}

export const explore = async ({lat, lng}) => {
  const action = 'spots-explore'
  const params = `?lat=${lat}&lng=${lng}&radius=500`
  const response = await get(action, params)

  return response.spots
}
