import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import Highlight from 'lib/components/Highlight'

import SocialLinks from './SocialLinks'

import { JOIN } from './content'


const sectionStyles = {
  backgroundColor: 'darkBlue.light',
  py: 8,
  textAlign: 'center',
  userSelect: 'none',
}

const Join = () => (
  <Box
    component="section"
    id="join"
    sx={sectionStyles}
  >
    <Container>
      <Grid container
        direction="column"
        height="100%"
        justifyContent="center"
      >
        <Grid item>
          <Typography gutterBottom variant="h2">
            {JOIN.TITLE_A} <Highlight>{JOIN.TITLE_B}</Highlight>
          </Typography>
          <Typography gutterBottom variant="body1">
            {JOIN.BODY}
          </Typography>
        </Grid>

        <Grid item sx={{ mt: 5 }} >
          <Stack
            direction="row"
            justifyContent="center"
          >
            <SocialLinks iconSize="large" />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

export default Join
