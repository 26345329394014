import {
  Box,
  Fab,
  Stack,
  Typography,
} from '@mui/material'
import {
  KeyboardArrowLeft as ArrowBack,
  KeyboardArrowRight as ArrowForward,
} from '@mui/icons-material'

const navigationIconStyles = {
  color: 'darkBlue.main',
  fontSize: 'large',
}

const NavigationButton = ({
  children,
  direction,
  isDisabled,
  onClick,
}) => (
  <Fab
    onClick={onClick}
    disabled={isDisabled}
    size="medium"
    sx={{
      [direction]: { xs: 5, sm: -75 },
      bgcolor: 'primary.main',
      display: isDisabled ? 'none' : 'absolute',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    }}
  >
    {children}
  </Fab>
)

function ChaptersCarousel({
  activeStep,
  setActiveStep,
  maxSteps,

  content: CHAPTERS_CONTENT,
 }) {
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const navigation = (
    <>
      <NavigationButton
        direction="left"
        isDisabled={activeStep === 0}
        onClick={handleBack}
      >
        <ArrowBack {...navigationIconStyles} />
      </NavigationButton>

      <NavigationButton
        direction="right"
        isDisabled={activeStep === maxSteps - 1}
        onClick={handleNext}
      >
        <ArrowForward {...navigationIconStyles} />
      </NavigationButton>
    </>
  )

  return (
    <Stack direction="row">
      {CHAPTERS_CONTENT.map(({
        alt,
        body,
        image,
        label,
        objectPosition,
      }, index) => (
        <Stack
          alignItems="stretch"
          direction="column"
          display={activeStep === index ? 'flex' : 'none'}
          key={`story-${label}`}
          spacing={2}
          sx={{ px: {xs: 0, sm: 10} }}
        >
          <Box position="relative">
            <img
              alt={alt}
              src={image}
              style={{
                height: '365px',
                width: '100%',
                borderRadius: '16px',
                objectFit: 'cover',
                objectPosition,
                overflow: 'hidden',
              }}
            />

            {navigation}
          </Box>
          <Typography variant="body1">
            {body}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}

export default ChaptersCarousel
