import { useState, useEffect, useCallback } from 'react'
import { Box } from '@mui/material'
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps'

import { explore } from '../data'

const MARKERS_BY_TYPE = {
  seed: (spot) => (
    <AdvancedMarker
      key={spot.id}
      title={spot.name}
      position={{
        lat: spot.lat,
        lng: spot.lng,
      }}
    >
      <div
        style={{
          height: 16,
          width: 16,
          background: '#1dbe80',
          border: '2px solid #0e6443',
          borderRadius: '50%',
          left: 0,
          position: 'absolute',
          top: 0,
          transform: 'translate(-50%, -50%)',
        }}></div>
    </AdvancedMarker>
  ),
}


function ExplorationMap({ position }) {
  const [spots, setSpots] = useState([])
  const [cameraState, setCameraState] = useState({
    center: position,
    heading: 0,
    tilt: 0,
    zoom: 17,
  })

  useEffect(() => {
    explore(position)
      .then(setSpots)
  }, [position])

  const handleCameraChange = useCallback((e) => {
    setCameraState(e.detail)
  }, [])

  const handleLocationChange = useCallback((e) => {
    const center = e.map.getCenter().toJSON()

    explore(center)
      .then(res => {
        const ids = spots.map(spot => spot.id)
        const results = res.filter(spot => !ids.includes(spot.id))

        return [...spots, ...results]
      })
      .then(setSpots)
  }, [spots])

  const playerMarker = (
    <AdvancedMarker
      title="You are here"
      position={position}
      onClick={() => { console.log(position, cameraState) }}
    />
  )

  const markers = spots.map(MARKERS_BY_TYPE.seed)

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API}>
      <Box style={{ height: '60vh' }}>
        <Map
          maxZoom={17}
          minZoom={15}
          mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
          onCameraChanged={handleCameraChange}
          onDragend={handleLocationChange}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
          {...cameraState}
          defaultCenter={position}
        >
          {playerMarker}
          {markers}
        </Map>
      </Box>
    </APIProvider>
  )
}

export default ExplorationMap
